import React, { useState } from 'react';

import styled from '@emotion/styled';
import { Box, Collapse, Avatar, Divider, Link, CollapseProps } from '@mui/material';
import { BoxProps } from '@mui/system';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';

import { IconButton, Typography } from '../../atoms';
import { formatCurrency, getInitials } from '../../../utils';
import { palette } from '../../../themes';
import { useTranslation } from 'react-i18next';
import { InfoTooltip } from '../InfoTooltip';

export type LeftPanelProps = {
    expanded?: boolean;
    data: {
        name: string;
        relationship: string | null;
        riskProfile: string;
        totalIncomeAvailable: number;
        totalRetirementAvailable: number;
        annualRetirementContributions: number;
        totalWealthAvailable: number;
        annualWealthContributions: number;
        goalAmount: number;
    };
    onEditIncomeClick?: () => void;
    onEditRiskProfile?: () => void;
    onEditInvestmentClick?: () => void;
};

const StyledLeftPanelContainer = styled(Box)<BoxProps & { expanded: boolean }>(({ expanded }) => ({
    width: expanded ? 200 : 72,
    height: '100%',
    fontFamily: 'TT Commons Pro' // added for storybook component which can be removed later
}));

const StyledIncomeContainers = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
}));

const StyledCollapsedContainer = styled(Collapse)<CollapseProps & { in: boolean }>(({ in: open }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: open ? '100%' : 0,
    minHeight: open ? 'inherit' : 0,
    '& .MuiCollapse-wrapper': {
        height: '100%',
        width: '100%',
        minHeight: 'inherit',
        '& .MuiCollapse-wrapperInner': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            minHeight: 'inherit'
        }
    }
}));

const StyledExpandedContainer = styled(Collapse)<CollapseProps & { in: boolean }>(({ in: open }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: open ? '100%' : 0,
    '& .MuiCollapse-wrapper': {
        height: '100%',
        width: '100%',
        '& .MuiCollapse-wrapperInner': {
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
            width: '100%'
        }
    }
}));

const StyledAvatar = styled(Avatar)(() => ({
    width: '32px',
    height: '32px',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '100%',
    color: palette.primary.main,
    backgroundColor: 'white'
}));

const StyledTitle = styled(Typography)(() => ({
    margin: 0
}));

const StyledTitleValue = styled(Typography)(() => ({
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '18px',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box'
}));

// const StyledCalculatedRemaining = styled(Typography)<any>(({ calculatedRemaining }) => ({
//     fontSize: '13px',
//     fontWeight: 600,
//     lineHeight: '16px',
//     margin: 0,
//     color: calculatedRemaining > 0 ? palette.table.score[50] : palette.table.score[100]
// }));

const StyledLabel = styled(Typography)(() => ({
    margin: 0
}));

const StyledIconButtonText = styled(Typography)(() => ({
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '100%',
    color: palette.common.blue,
    margin: 0
}));

const StyledLink = styled(Link)(() => ({
    color: palette.common.blue,
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '18px',
    textDecorationLine: 'underline',
    cursor: 'pointer'
}));

const StyledAvatarContainer = styled(Box)(() => ({
    margin: '24px auto'
}));

const StyledMainSection = styled(Box)(() => ({
    padding: '16px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
}));

const StyledIconButton = styled(IconButton)(() => ({
    width: '32px',
    height: '32px',
    color: palette.primary.main,
    marginBottom: '32px',
    marginTop: 'auto'
}));

const StyledSubheader = styled(Box)(() => ({
    display: 'flex',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    gap: '4px',
    flexWrap: 'wrap',
    flexShrink: 1,
    justifyContent: 'space-between'
}));

const StyledSection = styled(Box)(() => ({
    padding: '16px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
}));

const StyledCollapseContainer = styled('div')<BoxProps & { expanded: boolean }>(({ expanded }) => ({
    background: '#F4F5F7',
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.05)',
    minHeight: 'calc(100vh - 60px)',
    cursor: expanded ? 'auto' : 'pointer'
}));

export const LeftPanel = ({ data, onEditIncomeClick, onEditRiskProfile, onEditInvestmentClick }: LeftPanelProps) => {
    const { t } = useTranslation();
    const [expandedState, setExpandedState] = useState(true);
    // const calculatedRemaining = data?.goalAmount - data?.totalWealthAvailable;
    const handleClick = () => {
        setExpandedState(true);
    };

    const handleCollapse = (e: React.MouseEvent) => {
        e.stopPropagation();
        setExpandedState(false);
    };

    const StyledTruncatedTooltip = ({ text = '', avtarText = false }) => (
        <InfoTooltip message={text} placement="top-end">
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {avtarText ? (
                    <StyledTitleValue label={text} sx={{ marginLeft: '8px', maxWidth: '127px' }} />
                ) : (
                    <StyledTitleValue sx={{ maxWidth: '170px' }} label={data?.name} />
                )}
            </div>
        </InfoTooltip>
    );
    // const renderTotalRetirementAvailable = () => {
    //     return (
    //         <StyledIncomeContainers>
    //             <StyledLabel label={t('TOTAL_RETIREMENT_AVAILABLE')} />
    //             <StyledTitle label={formatter.format(data?.totalRetirementAvailable)} />
    //         </StyledIncomeContainers>
    //     );
    // };
    // const renderAnnualRetirementContributions = () => {
    //     return (
    //         <StyledIncomeContainers>
    //             <StyledLabel label={t('ANNUAL_RETIREMENT_CONTRIBUTIONS')} />
    //             <StyledTitle label={formatter.format(data?.annualRetirementContributions)} />
    //         </StyledIncomeContainers>
    //     );
    // };

    return (
        <StyledLeftPanelContainer expanded={expandedState} onClick={handleClick}>
            <StyledCollapseContainer expanded={expandedState}>
                <StyledCollapsedContainer in={!expandedState} orientation="horizontal" timeout={1}>
                    <StyledAvatarContainer>
                        <StyledAvatar>{getInitials(data?.name)}</StyledAvatar>
                    </StyledAvatarContainer>
                    <Divider sx={{ width: '100%' }} />
                    <StyledIconButton iconName={<ChevronRight />} onClick={handleClick} />
                </StyledCollapsedContainer>
                <StyledExpandedContainer in={expandedState} orientation="horizontal" timeout={1}>
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '8px 0',
                            marginBottom: '16px',
                            alignItems: 'center'
                        }}
                    >
                        <StyledAvatar>{getInitials(data?.name)}</StyledAvatar>
                        <StyledTruncatedTooltip text={data?.name} avtarText />
                    </Box>
                    <Divider sx={{ mx: '-16px' }} />
                    <StyledSection>
                        <StyledSubheader>
                            <StyledTitle variant="body.small" label={t('CLIENT')} />
                            <StyledLink>{t('LABEL_EDIT')}</StyledLink>
                        </StyledSubheader>
                        <StyledTruncatedTooltip text={data?.name} />
                    </StyledSection>
                    <Divider sx={{ mx: '-16px' }} />
                    <StyledSection>
                        <StyledSubheader>
                            <StyledTitle variant="body.small" label={t('CO-CLIENT')} />
                            <StyledLink>{t('LABEL_EDIT')}</StyledLink>
                        </StyledSubheader>
                        <InfoTooltip message={data?.relationship} placement="top-end">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <StyledTitleValue
                                    variant="body.smallSemibold"
                                    sx={{ maxWidth: '170px' }}
                                    label={data?.relationship}
                                />
                            </div>
                        </InfoTooltip>
                    </StyledSection>
                    <Divider sx={{ mx: '-16px' }} />
                    <StyledSection>
                        <StyledSubheader>
                            <StyledTitle variant="body.small" label={t('CLIENT_PROFILE_RISK_TOLERANCE_HEADING')} />
                            <StyledLink onClick={onEditRiskProfile}>{t('LABEL_EDIT')}</StyledLink>
                        </StyledSubheader>
                        <StyledTitle variant="body.smallSemibold" label={data?.riskProfile} />
                    </StyledSection>
                    <Divider sx={{ mx: '-16px' }} />
                    <StyledMainSection>
                        <StyledSubheader>
                            <StyledTitle variant="body.small" label={t('ACCOUNTS_LABEL_TEXT')} />
                            <StyledLink onClick={onEditInvestmentClick}>{t('LABEL_EDIT')}</StyledLink>
                        </StyledSubheader>
                        <StyledIncomeContainers>
                            <StyledTitle variant="labels.small" label={t('LABEL_TOTAL_WEALTH_AVAILABLE')} />
                            <StyledTitle
                                variant="body.smallSemibold"
                                label={formatCurrency(data?.totalWealthAvailable)}
                            />
                        </StyledIncomeContainers>
                        <StyledIncomeContainers>
                            <StyledLabel variant="labels.small" label={t('TOTAL_ANNUAL_CONTRIBUTIONS_TEXT')} />
                            <StyledTitle
                                variant="body.smallSemibold"
                                label={formatCurrency(data?.annualWealthContributions)}
                            />
                        </StyledIncomeContainers>
                    </StyledMainSection>
                    <Divider sx={{ mx: '-16px' }} />
                    <StyledMainSection>
                        <StyledSubheader>
                            <StyledLabel variant="body.small" label={t('TEXT_RETIREMENT_INCOME')} />
                            <StyledLink onClick={onEditIncomeClick}>{t('LABEL_EDIT')}</StyledLink>
                        </StyledSubheader>
                        <StyledIncomeContainers>
                            <StyledLabel variant="labels.small" label={t('LABEL_TOTAL_INCOME_AVAILABLE')} />
                            <StyledTitle
                                variant="body.smallSemibold"
                                label={formatCurrency(data?.totalIncomeAvailable)}
                            />
                        </StyledIncomeContainers>
                    </StyledMainSection>
                    {/* TODO:: enable when Retirement is available  */}
                    {/* {false && renderTotalRetirementAvailable()} */}
                    {/* TODO:: enable when Retirement is available  */}
                    {/* {false && renderAnnualRetirementContributions()} */}
                    {/* TODO:: Not for Genesis👇 uncomment when needed */}
                    {/* {calculatedRemaining !== 0 && (
                        <Box>
                            <StyledCalculatedRemaining
                                calculatedRemaining={calculatedRemaining}
                                label={`${calculatedRemaining > 0 ? '+' : '-'} $${Math.abs(
                                    calculatedRemaining
                                )?.toLocaleString()} ${calculatedRemaining > 0 ? 'needed' : 'unused'}`}
                            />
                        </Box>
                    )} */}

                    <Box
                        sx={{
                            position: 'sticky',
                            bottom: 0,
                            backgroundColor: '#F4F5F7',
                            padding: '16px',
                            margin: '32px -16px 0'
                        }}
                    >
                        {expandedState && (
                            <IconButton
                                onClick={handleCollapse}
                                iconName={
                                    <>
                                        <ChevronLeft />
                                        <StyledIconButtonText label={t('LABEL_COLLAPSE_CLIENT_DETAILS')} />
                                    </>
                                }
                                sx={{ padding: 0, color: '#3769FF' }}
                            />
                        )}
                    </Box>
                </StyledExpandedContainer>
            </StyledCollapseContainer>
        </StyledLeftPanelContainer>
    );
};
