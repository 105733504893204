import { useEffect, useState } from 'react';
import { uipApiInstance } from './services';
import { useAppDispatch, useAppSelector } from './common/hooks';
import { ClickWrapAgreement } from './components/molecules/ClickWrapAgreement/ClickWrapAgreement';
import { logEventToBackEnd, saveUserAgreementsSettings, updateGlobalLoaderState } from './features/global/globalSlice';
import { useOktaAuth } from '@okta/okta-react';
import { logout } from './features/auth/authSlice';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { updateHantzUserSettings } from '../src/features/auth/authSlice';

export const Hantz = ({ accessToken }: { accessToken: any }) => {
    const [data, setData] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const okta = useOktaAuth();
    const global = useAppSelector((state) => state.global);
    const user = useAppSelector((state) => state.auth.user);
    const dispatch = useAppDispatch();
    const goeConfig = useAppSelector((state) => state.global.goeConfig);

    useEffect(() => {
        // Get Hantz User Settings
        if (isEmpty(goeConfig)) {
            dispatch(updateHantzUserSettings());
        }
    }, [updateHantzUserSettings]);

    useEffect(() => {
        // Define the API endpoint
        const fetchData = async () => {
            try {
                const response = await uipApiInstance({
                    method: 'GET',
                    url: '/api/advice/getconfig'
                });
                const data = response;
                setData(data.data.hantzconfig.external_url);
            } catch (err: any) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []); // If bearer token changes, refetch data

    const handleLogout = async () => {
        updateGlobalLoaderState(true);
        try {
            const sessionExists = await okta.oktaAuth.session.exists();
            if (sessionExists) {
                await okta.oktaAuth.closeSession();
            }
        } finally {
            const isUserAuthenticated = okta.authState?.isAuthenticated;
            if (isUserAuthenticated) {
                await dispatch(logEventToBackEnd('LOGOUT_SUCCESS'));
                await okta.oktaAuth.tokenManager.clear();
                await okta.oktaAuth.clearStorage();
                dispatch(logout());
            }
        }
    };

    const handleAgreementComplete = async () => {
        await dispatch(
            saveUserAgreementsSettings({
                click_wrap_agreements: {
                    accepted: true,
                    userAggrementAcceptedTimeStamp: dayjs().format('YYYYMMDDHHmmss'),
                    agreements: [
                        {
                            agreement_name: 'UserAgreement',
                            acceptedUserAgreementVersion: user?.userAgreement?.version
                        }
                    ]
                }
            })
        );
    };

    const external_url = data;
    const char = external_url.indexOf('?') > -1 ? '&' : '?';

    const url = `${external_url}${char}access_token=${accessToken}`;
    if (loading) return <div>Loading...</div>;
    return (
        <>
            {global.showAgreementModal && (
                <ClickWrapAgreement
                    title="User Terms"
                    open={global.showAgreementModal}
                    agreements={[
                        {
                            title: 'Terms of Use Agreement',
                            content: user?.userAgreement?.text + user?.hantz?.text
                        }
                    ]}
                    onClose={handleLogout}
                    onComplete={() => handleAgreementComplete()}
                />
            )}
            {!global.showAgreementModal && <iframe src={url} className="hantzIframe" />}
        </>
    );
};
