import { styled, useTheme } from '@mui/material';
import { AdviceGoalPayloadType } from '../../../features/client-goals';
import { useMemo } from 'react';
import { MAX_ALLOWED_GOALS, RETIREMENT_CATEGORY_GOALS, WEALTH_CATEGORY_GOALS } from '../../../common/constants';
import { GoalTypeProps } from '../../../common/types';
import { Icon, Typography } from '../../atoms';
import { useTranslation } from 'react-i18next';
import { PATPalette } from '../../../themes/palette';
import { getIconForPDF } from '../../../utils/goalIconsMapperForPDF';

const IconSizeMapper: any = {
    small: { size: '16px' },
    medium: { size: '20px' },
    large: { size: '48px' }
};

const StyledGoalTypeParent = styled('button')(({}) => ({
    backgroundColor: 'transparent',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    margin: 0,
    padding: 0,
    ':disabled': {
        opacity: 0.3,
        cursor: 'not-allowed',
        '*': {
            pointerEvents: 'none'
        },
        '&:hover': {
            boxShadow: 'none'
        }
    }
}));

const paddingBySize = (size: GoalTypeProps['size']) => {
    const sizes = new Map<GoalTypeProps['size'], string>([
        ['small', '8px'],
        ['medium', '10px'],
        ['large', '26px']
    ]);

    return sizes.get(size) || 'inherit';
};

const StyledGoalTypeContainer = styled('div')<
    GoalTypeProps & {
        isClickable: boolean;
        goalTypeInfo?: GoalTypeProps;
        backgroundColor?: string;
        isFocused: GoalTypeProps['isFocused'];
    }
>(({ theme, type, size, isClickable, goalTypeInfo, backgroundColor, isFocused }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: type === goalTypeInfo?.type ? goalTypeInfo?.color : 'inherit',
    border: isFocused === type && isClickable ? `1px solid ${goalTypeInfo?.color}` : '1px solid transparent',
    backgroundColor,
    borderRadius: size === 'large' ? '6px' : '50%',
    padding: paddingBySize(size),
    boxShadow: '0px 1px 2px 0px transparent',
    '&:hover': isClickable && {
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30)'
    },
    '&:focus': isClickable && {
        border: `1px solid ${goalTypeInfo?.color}`,
        backgroundColor: type === goalTypeInfo?.type ? theme.palette.common.white : 'inherit'
    },
    '&:active': isClickable && {
        border: 'none',
        color: theme.palette.common.white,
        svg: {
            fill: theme.palette.common.white,
            color: theme.palette.common.white,
            '*': {
                path: {
                    stroke: theme.palette.common.white
                }
            }
        },
        backgroundColor: type === goalTypeInfo?.type ? goalTypeInfo?.darkBackground : 'inherit'
    }
}));

export const GoalType = ({
    size = 'medium',
    type,
    onClickHandler,
    isFocused,
    adviceGoals,
    isPDF
}: GoalTypeProps & { adviceGoals?: AdviceGoalPayloadType; isPDF?: boolean }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const goalTypeInfo = [...RETIREMENT_CATEGORY_GOALS, ...WEALTH_CATEGORY_GOALS].find((g) => g.type === type);

    const isClickable = size === 'large';

    const backgroundColor = useMemo(() => {
        if (isFocused === type && isClickable) {
            return type === goalTypeInfo?.type ? theme.palette.common.white : 'inherit';
        }

        return type === goalTypeInfo?.type ? goalTypeInfo?.backgroundColor : 'inherit';
    }, [isFocused, type, goalTypeInfo, theme, isClickable]);

    const isMaxGoalsAdded = useMemo(
        () => isClickable && adviceGoals && adviceGoals?.formFields?.length >= MAX_ALLOWED_GOALS,
        [isClickable, adviceGoals]
    );

    return (
        <StyledGoalTypeParent
            tabIndex={0}
            disabled={isMaxGoalsAdded || goalTypeInfo?.disabled}
            onClick={onClickHandler}
        >
            <StyledGoalTypeContainer
                tabIndex={-1}
                size={size}
                type={type}
                aria-label={goalTypeInfo?.title}
                category={''}
                isClickable={isClickable}
                backgroundColor={backgroundColor}
                isFocused={isFocused}
                goalTypeInfo={goalTypeInfo}
            >
                {isPDF ? (
                    getIconForPDF(type)
                ) : (
                    <Icon
                        id={goalTypeInfo?.icon!}
                        size={IconSizeMapper[size] ? IconSizeMapper[size].size : size}
                        fill={goalTypeInfo?.color}
                    />
                )}
            </StyledGoalTypeContainer>
            {size === 'large' && (
                <Typography
                    label={t(goalTypeInfo?.title ? goalTypeInfo.title.toUpperCase() : '')}
                    variant="labels.large"
                    sx={{ color: PATPalette.neutral.grey[400] }}
                    mt="8px"
                />
            )}
        </StyledGoalTypeParent>
    );
};
