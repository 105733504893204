import { useEffect } from 'react';

const StorybookRedirect = () => {
    useEffect(() => {
        window.open('/storybook/index.html', '_blank');

        setTimeout(() => {
            window.location.href = '/';
        }, 1000);
    }, []);

    return null;
};

export default StorybookRedirect;
